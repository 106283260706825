<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <operacioncrm-form
          :tabla="tabla"
          :idempleado="usuarioIdempleado"
          :iddelegacion="usuarioIddelegacion"
          :es-tecnico="!!usuarioIdtecnico"
          :es-comercial="!!usuarioIdcomercial"
          :es-vigilante="!!usuarioIdvigilante"
          :descripcion="routeQuery.descripcion"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import OperacioncrmForm from '../components/OperacioncrmForm'
import Data from './OperacioncrmAddData'
import { get } from 'vuex-pathify'

export default {
  components: {
    OperacioncrmForm
  },
  mixins: [formPageMixin],
  props: {
    tabla: {
      type: Object,
      default: () => {},
    },
    viewRoute: {
      type: String,
      default: '',
    }
  },
  computed: {
    usuarioIdempleado: get('usuario/idempleado'),
    usuarioIddelegacion: get('usuario/iddelegacion'),
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
  },
  async created () {
    // routeQuery: descripcion, cuando creas la operación desde un sitio en particular, por ejemplo partes.
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir operación'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      const identificador = this.$online.operacioncrm.getIdentificador(this.tabla, this.routeParams)
      const inserted = await Data.insert(
        this, this.formData, this.tabla, identificador, this.usuarioIdempleado
      )
      this.$appRouter.replace({
        name: this.viewRoute,
        params: {
          idoperacioncrm: inserted.idoperacioncrm
        },
      })
    },
  },
}
</script>
